<template>
  <div
    class="offer-item"
    :class="{ flexible, fromSection }"
  >
    <NuxtLink
      :to="dragging ? undefined : offer.route"
      draggable="false"
    >
      <div class="img-container">
        <img
          v-if="offer.image_desktop?.url"
          :src="offer.image_desktop.url"
          :alt="`image de l'offre ${offer.title}`"
          draggable="false"
          loading="lazy"
          width="1070"
          height="590"
        />
      </div>
      <div
        v-if="showShops"
        class="layer-shop"
      >
        <h4 class="available">Disponible en magasin</h4>
        <ul>
          <li
            v-for="shop in offer.retailers"
            :key="shop.link"
          >
            <p
              target="_blank"
              rel="noopener noreferrer"
              @click.prevent="openInNewTab(shop)"
            >
              {{ shop.retailer.name }}
            </p>
          </li>
        </ul>
      </div>
      <div class="bottom-container">
        <div class="content">
          <h4 class="discount">{{ offer.sur_title }}</h4>
          <h5 class="title">{{ offer.title }}</h5>
          <p class="description">{{ offer.description }}</p>
        </div>

        <div class="buttons">
          <div
            v-if="offer.url_playstation_store"
            class="button orange"
            @click.prevent="openPSStore(offer.url_playstation_store)"
          >
            Voir l'offre
          </div>
          <div
            v-if="offer.retailers?.length"
            class="button button-line orange"
            @click.prevent="tryShowShop"
          >
            Acheter en magasin
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.328 34.43"
              :class="{ showShops }"
            >
              <path
                d="M165.21-130.78l-15.05-15.1a2.178,2.178,0,0,0-3.041,0,2.178,2.178,0,0,0,0,3.041l13.555,13.555L147.119-115.73a2.178,2.178,0,0,0,0,3.041,2.178,2.178,0,0,0,3.041,0l15.05-15.05A2.178,2.178,0,0,0,165.21-130.78Z"
                transform="translate(-146.5 146.5)"
                fill="#FFFFFF"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import type {
  CustomSimpleOffer,
  Offer,
  OfferRetailer,
  WithRoute,
} from '~/types'

withDefaults(
  defineProps<{
    offer: WithRoute<Offer> | WithRoute<CustomSimpleOffer> | CustomSimpleOffer
    flexible?: boolean
    fromSection?: boolean
    dragging?: boolean
  }>(),
  {
    flexible: false,
    fromSection: false,
    dragging: false,
  },
)

const showShops = ref(false)
const { gtag } = useGtag()

const openPSStore = (url: string) => {
  gtag('event', 'cta_click', {
    cta_name: `Voir l'offre`,
  })
  window.open(url, '_blank')?.focus()
}

const openInNewTab = (shop: OfferRetailer) => {
  window.open(shop.link, '_blank')?.focus()
}

const tryShowShop = () => {
  showShops.value = !showShops.value
}
</script>

<style lang="scss">
.offer-item {
  position: relative;
  width: 107rem;
  height: 59rem;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 21.9rem;
    height: 13rem;
    background-image: url('~/assets/img/offer-corner.png');
    background-size: cover;
  }

  &.flexible {
    width: 100%;

    .bottom-container {
      .buttons {
        flex-wrap: wrap;
        justify-content: flex-end;

        .button {
          flex-shrink: 0;
          margin-right: 0;
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
            margin-left: 2rem;
          }
        }
      }
    }
  }

  .img-container {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      transform: translateZ(0);
      transition: transform 1s;
      object-fit: cover;
      object-position: top;
    }
  }

  .layer-shop {
    position: absolute;
    inset: 0 0 17.4rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    padding: 9rem 5rem 5rem;
    background: rgba(#000, 0.7);
    backface-visibility: hidden;
    perspective: 1000;
    cursor: default;
    transform: translate3d(0, 0, 0);

    h4 {
      color: $white;
      font-size: 3.2rem;
      font-weight: 400;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding-left: 50%;
    }

    li {
      flex-grow: 0;
      flex-basis: 50%;
    }

    p {
      margin-bottom: 1rem;
      color: $white;
      font-size: 1.8rem;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .bottom-container {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 17.4rem;
    padding: 0 5rem;
    background: rgba(#000, 0.7);
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);

    .content {
      margin-bottom: 1rem;
      color: $white;
      text-align: left;

      .discount {
        font-size: 3.6rem;
        font-weight: bold;
        line-height: 4.6rem;
      }

      .title {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
      }

      .description {
        max-width: 36.5rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }

    .buttons {
      display: flex;

      .button {
        display: inline-flex;
        align-items: center;
        height: 4.6rem;
        margin-right: 1.6rem;
        padding: 0 3rem;
        color: $white;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 4.6rem;
        text-decoration: inherit;
        background: $blue;
        transition: opacity 0.4s;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: 0.5;
        }

        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
        }

        &.orange {
          background: $orange;

          svg {
            &.showShops {
              transform: rotate(-90deg);
            }

            path {
              fill: $orange;
            }
          }
        }

        &.button-line {
          color: $blue;
          background: transparent;
          border: 1px solid $blue;

          &.orange {
            color: $orange;
            border: 1px solid $orange;
          }
        }

        &.white {
          color: $blue;
          background: $white;
        }

        svg {
          width: 0.75rem;
          height: 1.33rem;
          margin-left: 1.6rem;
          transform: rotate(90deg);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .offer-item {
    &.flexible {
      &.fromSection {
        .bottom-container {
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: auto;
          padding: 3rem;

          .content {
            width: 100%;
            margin-bottom: 3rem;

            .discount {
              font-size: 3rem;
              line-height: 3.9rem;
            }

            .description {
              max-width: 100%;
              font-size: 1.1rem;
              line-height: 1.5rem;
            }
          }

          .buttons {
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;

            .button {
              display: inline-flex;
              flex-shrink: 0;
              margin-right: auto;
              margin-bottom: 1rem;
              padding: 0 2rem;

              &:last-child {
                margin-bottom: 0;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .offer-item {
    .layer-shop {
      position: absolute;
      bottom: 0;
      justify-content: flex-start;
      padding: 9rem 3rem 28rem;

      ul {
        margin-top: 2rem;
        padding-left: 0%;
      }

      h4 {
        line-height: 1.2;
      }
    }

    &.flexible {
      .bottom-container {
        .buttons {
          justify-content: flex-start;

          .button {
            flex-shrink: 0;
            margin-right: auto;
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
              margin-left: 0;
            }
          }
        }
      }
    }

    .bottom-container {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: auto;
      padding: 3rem;

      .content {
        margin-bottom: 3rem;

        .discount {
          font-size: 3rem;
          line-height: 3.9rem;
        }

        .description {
          max-width: 100%;
          font-size: 1.1rem;
          line-height: 1.5rem;
        }
      }

      .buttons {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;

        .button {
          display: inline-flex;
          margin-right: auto;
          margin-bottom: 1rem;
          padding: 0 2rem;

          &:last-child {
            margin-right: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .offer-item {
    width: 27.5rem;
  }
}
</style>
